<template>
  <section class="info">
    <div class='scrollBar'  :style="{ opacity: showScrollBar ? 1 : 0 }">
      <div class='circle'></div>
    </div>
    <div class="section1">
      <PostComponent v-for="post in posts" :key="post.id" :title="post.title" :info="post.text" :catego="post.label" @check-overflow="checkOverflow"/>
    </div>
  </section>
</template>

<script>
import PostComponent from "./PostComponent";
import { gsap } from "gsap";
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import Draggable from 'gsap/dist/Draggable';

gsap.registerPlugin(ScrollTrigger, Draggable);

export default {
  name: "PostsView",
  components: {
    PostComponent,
  },
  props: {
    posts: Array,
  },
  data() {
    return {
      showScrollBar: false,
    };
  },
  methods: {

    checkOverflow() {
      const section1 = this.$el.querySelector('.section1');
      this.showScrollBar = section1.scrollHeight > section1.clientHeight;
    },
    scrollToMiddle() {
      const section1 = this.$el.querySelector('.section1');
      section1.scrollTo({
        top: section1.scrollHeight / 2,
        behavior: "smooth"
      });
    },
    setupScrollTween() {
      const section1 = this.$el.querySelector('.section1');
      const circle = this.$el.querySelector('.circle');

      let scrollTween = gsap.to(circle, {
        y: 250,
        ease: "none",
        scrollTrigger: {
          trigger: section1,
          start: "top top",
          end: "bottom bottom",
          scrub: true
        }
      });

      Draggable.create('.circle', {
        type: "y",
        bounds: '.scrollBar',
        inertia: true,
        onPress() {
          scrollTween.scrollTrigger.disable(false);
        },
        onDrag() {
          let progress = gsap.utils.normalize(this.minY, this.maxY, this.y);
          let to = section1.scrollHeight * progress;
          section1.scrollTo(0, to);
        },
        onRelease() {
          let progress = gsap.utils.normalize(this.minY, this.maxY, this.y);
          scrollTween.scrollTrigger.enable();
          scrollTween.progress(progress);
        }
      })[0];
    }
  },
  mounted() {
    this.checkOverflow();
    this.setupScrollTween();
    window.addEventListener('resize', this.checkOverflow); // Check overflow on resize
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkOverflow);
  }
};


</script>

<style scoped lang="css">
.info {
  height: calc(100vh - 80px);
    position: absolute;
    width: 800px;
}
.scrollBar{
  z-index: 2;
    position: absolute;
    width: 7px;
    height: calc(100% - 10px);
    background: #dee2e63d;
    top: 0;
    bottom: 0;
    left: -2px;
    /* right: 0; */
    margin: auto;
}

.circle{
  width: 7px;
    height: 30px;
    border-radius: 50px;
    background: #929192;
}

.section1{
  height: 100%;
    width: 750px;
    overflow: hidden;
}



</style>
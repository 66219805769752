<template>
  <article class="post">
    <header>
      <h4 @click="toggleExpanded" class="post-title">
        {{ title }}
      </h4>
      <div class="catego">[{{ catego }}]</div>
      <button class="btn_accord" @click="toggleExpanded">
        <div class="btn-ico">
          <svg :style="buttonStyle1" fill="#FFFFFF" height="60%" width="60%" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 490 490" xml:space="preserve">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
              <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 "></polygon> 
            </g>
          </svg>
          <svg :style="buttonStyle2" fill="#FFFFFF" height="60%" width="60%" version="1.1" id="Layer_1"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330"
            xml:space="preserve">
            <g id="SVGRepo_bgCarrier" stroke-width="0" />
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
            <g id="SVGRepo_iconCarrier">
              <path id="XMLID_225_"
                d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
            </g>
          </svg>
        </div>
        <div class="btn_label">[OPEN]</div>
      </button>
    </header>
    <div :style="contentStyle" class="content">
      <p :style="infoStyle" class="info">{{ info }}</p>
    </div>
  </article>
</template>

<script>
import {  ref, computed, watch } from "vue";

export default {
  name: "PostComponent",
  props: {
    title: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      required: true,
    },
    catego: {
      type: String,
      required: false,
    },
    btn1: {
      type: String,
      required: false,
    },
  },
  emits: ['check-overflow'],
  setup(props, { emit }) {
    console.log(props)
    const expanded = ref(false);
    
    const contentStyle = computed(() => {
      return { "max-height": expanded.value ? "fit-content" : 0 };
    });

    const infoStyle = computed(() => {
      return { opacity: expanded.value ? 1 : 0 };
    });

    const buttonStyle2 = computed(() => {
      return { display: expanded.value ? "none" : "initial" };
    });

    const buttonStyle1 = computed(() => {
      return { display: expanded.value ? "initial" : "none" };
    });

    // Add a delay before emitting the event
    const emitDelayedCheckOverflow = (delay) => {
      setTimeout(() => {
        emit('check-overflow');
      }, delay);
    };

    // Watch the expanded value to emit event when it changes
    watch(expanded, (newVal) => {
      console.log(newVal);
      emitDelayedCheckOverflow(300); 
    });

    const toggleExpanded = () => {
      expanded.value = !expanded.value;
    };


    return {  expanded, contentStyle, infoStyle, toggleExpanded, buttonStyle1, buttonStyle2 };
  },
};
</script>

<style scoped lang="css">
.post {
  padding: 1rem 1.5rem;
  border: 2px solid var(--clr-grey-special);
  margin-bottom: 1rem;
  border-radius: var(--radius);
  box-shadow: var(--light-shadow);
  font-family: ibm_plex_monotext;
  color: #929191;
  font-size: 0.8vw;
}

.post h4 {
  width: fit-content;
  font-size: 0.8vw;
  letter-spacing: 11px;
  text-align: left;
  text-transform: uppercase;
  border: 0px solid #929191;
  border-bottom: 4px solid #929191;
  line-height: 53px;
  padding-right: 40px;
}

.post p {
  color: var(--clr-grey-3);
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.post header {
  display: block;
  position: relative;
}

.post header h4 {
  margin-bottom: 0;
}

.btn {
  background: transparent;
  border-color: transparent;
  width: 2rem;
  height: 2rem;
  background: var(--clr-grey-special);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--clr-red-special);
  cursor: pointer;
  margin-left: 1rem;
  align-self: center;
  min-width: 2rem;
  z-index: 1;
}

.post-title {
  cursor: pointer;
}

.catego {
  width: 580px;
  position: absolute;
  text-align: right;
  top: 0px;
  line-height: 55px;
  /* box-sizing: border-box; */
  /*-moz-box-sizing: border-box;
    /* -webkit-box-sizing: border-box; */
  border: 0px solid #929191;
  border-bottom: 1px solid #929191;
  letter-spacing: 5px;
  font-size: 0.7vw;
  text-transform: uppercase;
}

.btn-ico {
  width: 22Px;
    height: 20px;
    /* left: -25px; */
    background: #929192;
    position: absolute;
    left: -73px;
}

.btn_label {
  letter-spacing: 5px;
    font-size: 0.7vw;
    text-transform: uppercase;
    /* margin: -23px; */
    /* margin-left: -18px; */
    color: #929192;
    width: auto;
    max-width: 80px;
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
}
.btn_accord{
  width: auto;
    height: auto;
    border: 0px;
    background: transparent;
    /* display: flex; */
    position: absolute;
    top: 16px;
    right: 25px;
}

.btn_accord:hover, .btn_accord:active, .btn_accord:visited, .btn_accord:focus, btn_accord:focus-visible{
    border: 0px;
    background: transparent;
    outline: 0px;
}

.content {
  max-height: 0;
  transition: max-height 0.2s ease-out;
  width: 580px;
}

.info {
  z-index: -1;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  max-height: calc(100vh - 60px);
    overflow-y: clip;
}

@media screen and (min-width: 2000px) {

  .btn_label, .catego, .post, .post h4 {
    font-size: 11px;
  }

}
</style>
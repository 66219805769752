const auth = {
    state: () => ({
        token: localStorage.getItem('token'),
    }),
    mutations: {
        setToken(state, token) {
            state.token = token;
            localStorage.setItem('token', token);
        },
        clearToken(state) {
            state.token = null;
            localStorage.removeItem('token');
        },
    },
};
export default auth;

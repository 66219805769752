import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-bcaba074"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "menu" };
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (item) => {
            return (_openBlock(), _createElementBlock("li", {
                key: item.id,
                onClick: ($event) => (_ctx.changeUrl(item.id))
            }, _toDisplayString(item.name), 9, _hoisted_2));
        }), 128))
    ]));
}

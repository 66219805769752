import structure from '../static/structure.json';
import news from '../static/news.json';
import api from '../api';
export default class ClusterAPI {
    async getArray(cat) {
        switch (cat) {
            case 'pages': {
                const pageFetcher = new PageFetcher();
                const pages = await pageFetcher.getPages();
                return pages;
                //console.log(array);
                //return structure.pages;
            }
            case 'slider':
                return structure.slider;
            case 'news':
                return news;
            default:
                return news;
            // code block
        }
        /* return axios.get('/static/structure.json').then((response:any) => {
          if(response.data && response.data.pages)
           return response.data.pages;
          else
           return response.data
        }); */
    }
    getSliders() {
        return structure.slider;
        // code block
        /* return axios.get('/static/structure.json').then((response:any) => {
          if(response.data && response.data.pages)
           return response.data.pages;
          else
           return response.data
        }); */
    }
    getVueObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }
}
class PageFetcher {
    async fetchPages() {
        try {
            const response = await api.get('page_list.php', {
                params: { cat: "Pages" }
            });
            console.log(response.data);
            // Ensure the response is an array
            return Array.isArray(response.data) ? response.data : [response.data];
        }
        catch (error) {
            console.error('Error fetching pages:', error);
            return [];
        }
    }
    async getPages() {
        const pages = await this.fetchPages();
        return pages; // This will be an array due to fetchPages implementation
    }
}

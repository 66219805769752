// src/router.ts
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../pages/Home.vue';
import TemplatePage from '../pages/Template.vue';
import HelloWorld from '../pages/HelloWorld.vue';
import LoginPage from '../pages/admin/LoginPage.vue';
import UserManagement from '../pages/admin/UserManagement.vue';
import PostManagement from '../pages/admin/PostManagement.vue';
import LandingPage from '../pages/admin/LandingPage.vue';
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { title: 'Home Page' }
    },
    {
        path: '/template/:page',
        name: 'TemplatePage',
        component: TemplatePage,
        meta: { title: 'Home Page' }
    },
    {
        path: '/hallo',
        name: 'HelloWorld',
        component: HelloWorld,
        meta: { title: 'Home Page' }
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
        meta: { title: 'Login' }
    },
    {
        path: '/landing/:page',
        name: 'LandingPage',
        component: LandingPage,
        meta: { requiresAuth: true }
    },
    {
        path: '/user',
        name: 'Users',
        component: UserManagement,
    },
    {
        path: '/post',
        name: 'Posts',
        component: PostManagement,
    }
    // Add more routes as needed
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
// Global navigation guard to set the document title
router.beforeEach((to, from, next) => {
    // Set the document title to the title specified in the meta field
    if (to.meta && to.meta.title && typeof (to.meta.title) === 'string') {
        document.title = to.meta.title;
    }
    else {
        document.title = 'Default Title'; // Fallback title
    }
    const loggedIn = sessionStorage.getItem('userEva');
    if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
        next({ name: 'Login' });
    }
    else {
        next();
    }
});
export default router;

<template>
    <div class="containerBox">
        <div class="logo"><img class="img4" alt="default" src="../../assets/logos/logo.png"></div>
        <nav class="sidebar">
            <ul>
                <li v-if="user.mode === 'admin'"><a href="#" @click.prevent="routering('users')"
                        :class="{ active: isActive('Users') }">Users</a></li>
                <li><a href="#" @click.prevent="routering('news')" :class="{ active: isActive('News') }">News</a></li>
                <li><a href="#" @click.prevent="routering('shows')" :class="{ active: isActive('Shows') }">Shows</a></li>
                <li><a href="#" @click.prevent="routering('works')" :class="{ active: isActive('Works') }">Works</a>
                </li>
                <li><a href="#" @click.prevent="routering('projects')"
                        :class="{ active: isActive('Projects') }">Projects</a>
                </li>
                <li><a href="#" @click.prevent="routering('press')" :class="{ active: isActive('Press') }">Press</a>
                </li>
                <li class="disabled"><a href="#" @click.prevent="routering('editcontact')"
                        :class="{ active: isActive('About') }">About</a></li>
                <li class="disabled"><a href="#" @click.prevent="showSlider" :class="{ active: isActive('Slider') }">Sliders</a></li>
                <li><button @click="disconnect">Disconnect</button></li>
            </ul>
        </nav>
        <main class="contentBox">
            <component :is="currentComponent" :title="currentComponentLabel" @editItem="editItem"
                :item="currentSelectedItem" :key="currentComponentKey"/>
        </main>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import UserManagement from './UserManagement.vue';
import PostManagement from './PostManagement.vue';
import EditorUser from './EditorUser.vue';
import { useRouter, useRoute } from 'vue-router';
import EditorAbout from './EditorAbout.vue';
import EditorItem from './EditorItem.vue';

const router = useRouter();
const route = useRoute();

const currentComponent = ref(UserManagement);
const currentComponentKey = ref('');

let currentPage = route.params.page;
let currentComponentLabel = 'Users';

let user = { admin: "" };
if (sessionStorage.getItem('userEva'))
    user = JSON.parse(sessionStorage.getItem('userEva')).user;


const routering = (page) => {
    router.push(page);
}

const navUrl = () => {
    currentPage = route.params.page;
    currentComponent.value = '';

    let componentKey = currentPage; 

    switch (currentPage) {
        case 'projects': {
            currentComponentLabel = 'Projects';
            currentComponent.value = PostManagement;
            break;
        }
        case 'news': {
            currentComponentLabel = 'News';
            currentComponent.value = PostManagement;
            break;
        }
        case 'shows': {
            currentComponentLabel = 'Shows';
            currentComponent.value = PostManagement;
            break;
        }
        case 'press': {
            currentComponentLabel = 'Press';
            currentComponent.value = PostManagement;
            break;
        }
        case 'works': {
            currentComponentLabel = 'Works';
            currentComponent.value = PostManagement;
            break;
        }
        case 'users': {
            if (user.mode != 'admin') {
                routering('news');
            } else {
                currentComponentLabel = 'Users';
                currentComponent.value = UserManagement;
            }
            //statements; 
            break;
        }
        case 'edituser':{
            currentComponentLabel = 'Users';
            currentComponent.value = EditorUser;
            break;
        }
        case 'edititem':{
            //currentComponentLabel = 'Item';
            currentComponent.value = EditorItem;
            break;
        }
        case 'editcontact':{
            currentComponentLabel = 'About';
            currentComponent.value = EditorAbout;
            break;
        }
        default: {
            break;
        }
    }
    currentComponentKey.value = componentKey;
};

let currentSelectedItem = {};

const editItem = (item) => {
    switch (currentComponentLabel) {
        case 'Users': {
            //console.log(item);
            currentSelectedItem = item;
            console.log(currentSelectedItem, "currentSelectedItem");
            router.push('edituser?item='+item.id)
            //statements; 
            break;
        }
        case 'Press':
        case 'Projects':
        case 'Works':
        case 'Shows':
        case 'News': {
            //console.log(item);
            currentSelectedItem = item;
            console.log(currentSelectedItem, "currentSelectedItem");
            router.push('edititem?item='+item.id)
            //statements; 
            break;
        }
        default: {
            //statements; 
            break;
        }
    }
};

if (currentPage) {
    navUrl();
}

const isActive = (componentName) => {
    return currentComponentLabel === componentName;
};

const disconnect = () => {
    // Implement your disconnect logic here, e.g., clearing user data, token, etc.
    console.log('User disconnected');
    sessionStorage.removeItem('userEva');
    // Redirect to the login page or any other page
    router.push('/login'); // Adjust the path as necessary
};

watch(
    () => route.params.page,
    (newId, oldId) => {
        console.log(newId, oldId);
        // react to route changes...
        navUrl(newId);
    }
)

</script>

<style scoped>
.logo {
    position: absolute;
    left: 50%;
    top: 10px;
}

.containerBox {
    display: inline-flex;
    max-width: 80%;
    margin: auto;
}

.sidebar {
    width: 220px;
    background-color: #f4f4f4;
    padding: 15px;
    font-size: 14px;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
    width: 100px;
}

.sidebar ul li {
    margin-bottom: 10px;
}

.sidebar ul li a,
.sidebar ul li button {
    text-decoration: none;
    color: #42b983;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px 0;
    display: block;
    width: 100%;
    text-align: left;
    transition: all .1s ease-in;
}

.sidebar ul li a:hover,
.sidebar ul li button:hover {
    letter-spacing: 2px;
    
}

.sidebar ul li a.active {
 font-weight: bold;
}

.contentBox {
    flex: 1;
    padding: 15px;
}

.disabled{
    pointer-events: none;
    opacity:0.4;

}
</style>
import { createApp } from 'vue';
import { createVfm } from 'vue-final-modal';
import App from './App.vue';
import router from './services/Router';
import store from './services/stores';
const vfm = createVfm();
createApp(App)
    .use(router)
    .use(vfm)
    .use(store)
    .mount('#app');
